<template>
  <v-container fill-height fluid>
    <v-card
      elevation="4"
      class="mx-auto"
    >
      <v-card-title>{{ $t('reset_your_password') }}</v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          v-on:submit.prevent="onSubmit"
          lazy-validation
        >
          <v-text-field
            :label="$t('new_password')"
            v-model="newPasswd"
            :rules="passwdRules"
            type="password"
            prepend-inner-icon="mdi-lock-outline"
            required
            outlined
          ></v-text-field>

          <v-text-field
            :label="$t('repeat_password')"
            v-model="repeatPasswd"
            :rules="confirmPasswdRules"
            type="password"
            prepend-inner-icon="mdi-lock-outline"
            required
            outlined
          ></v-text-field>

          <v-btn
            :disabled="loading"
            class="primary"
            large
            block
            :loading="loading"
            @click="submit"
          >
            <span>{{ $t('save') }}</span>
          </v-btn>
        </v-form>
      </v-card-text>

    </v-card>
  </v-container>
</template>

<style>
  
</style>

<script>
import { mapState, mapActions } from 'vuex'
import router from "@/router";

import AuthActions from "@/store/core/auth/actions-types";
import UserActions from '@/store/core/user/actions-types'
import NetworkShareActions from '@/store/content/network_share/actions-types'

export default {
  name: "ResetPassword",

  data() {
    return {
      valid: false,
      loading: false,
      newPasswd: null,
      repeatPasswd: null,
      passwdRules: [
        v => !!v || this.$t('form_rules.mandatory'),
        v => (v && v.length > 3) || this.$t('form_rules.min_length', {name: this.$t('password'), min: "4"}),
      ],
      confirmPasswdRules: [
        v => !!v || this.$t('form_rules.mandatory'),
        v => (v === this.newPasswd) || this.$t('form_rules.confirm_password')
      ],
      error: null
    };
  },

  computed: mapState({
    userData: state => state.core.auth.userData,
  }),
  
  methods: {
    ...mapActions('core/auth', [
      AuthActions.DO_LOGIN
    ]),

    ...mapActions('core/user', [
      UserActions.UPDATE_USER,
    ]),

    ...mapActions('content/network_share', [
      NetworkShareActions.CONFIGURE_SHARE_USER,
    ]),

    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;

      const updateData = {passwd: this.repeatPasswd, usersId: this.userData.users_id}

      const credentials = {username: this.userData.email, password: this.newPasswd};

      this[UserActions.UPDATE_USER](updateData)
        .then(() => {
          this[NetworkShareActions.CONFIGURE_SHARE_USER]({usersId: this.userData.users_id, data: {password: this.newPasswd}})
            .then(() => {
              this[AuthActions.DO_LOGIN](credentials)
                .then(() => {
                  router.push('/')
                  this.loading = false;
                })
            })
        })
    }
  }
};
</script>
